import React from 'react';
import TitleEffect from "../../../components/TitleEffect";
import PageTitle from "../../../components/PageTitle";
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux';
import { setIsEditing } from '../../../shared/store/slices/customers'
import CustomerDocumentsList from './CustomerDocumentsList';
import { AccountStatusMessage } from "./Utils";

function CustomerProfileViewPage(props) {

    TitleEffect(PageTitle("Customer Profile"));

    const profiledatabigStyle = {
        display: "flex",
        alignItems: "center",
        marginTop: "-1px"
    };

    const customer = useSelector(state => state.customers.current);
    const href = "mailto:" + customer?.email;

    const dispatch = useDispatch();

    const handleEdit = () => {
        dispatch(setIsEditing(true));
    }

    const handleCopy = () => {
        navigator.clipboard.writeText(customer.email);
        return true;
    }

    return <>
        <div className="profilegrid">
            <div  className="profilegrid1">
                <div className="profilelabel">Email</div>
                <div className="profiledatabig" style={profiledatabigStyle}>
                    <a href={href}>{customer?.email}</a>
                    <button className="copybutton" onClick={() => { handleCopy() }}>Copy</button>
                </div>
                <div className="profilelabel">Name</div>
                <div className="profiledatabig">{customer?.givenName + ' ' + customer?.familyName}</div>
            </div>
            <div className="profilegrid2">
                <div className="profilelabel">Available Balance</div>
                <div className="profiledatabig bold green">{customer?.availableBalance}</div>
                <div className="profilelabel">Total Balance</div>
                <div className="profiledatabig">{customer?.totalBalance}</div>
            </div>
            <div className="profilegrid3">
                <div className="profilelabel">Address</div>
                <div>{customer?.address?.street}</div>
                <div className="profilelabel">City</div>
                <div>{customer?.address?.locality}</div>
                <div className="profilelabel">State</div>
                <div>{customer?.address?.region}</div>
                <div className="profilelabel">Country</div>
                <div>{customer?.address?.country}</div>
                <div className="profilelabel">Postal Code</div>
                <div>{customer?.address?.postalCode}</div>
                <div className="profilelabel">Mobile</div>
                <div>{customer?.mobile}</div>
                <div className="profilelabel">Phone</div>
                <div>{customer?.phoneNumber}</div>
                <div className="profilelabel">Date of Birth</div>
                {customer?.dateOfBirth &&
                    <div>{moment(customer?.dateOfBirth).format("YYYY-MM-DD")}</div>
                }
                <div className="profilelabel">Gender</div>
                <div>{customer?.gender}</div>
                <div className="profilelabel">Occupation</div>
                <div>{customer?.occupation}</div>
                <div className="profilelabel">Industry</div>
                <div>{customer?.industry}</div>
            </div>
            <div className="profilegrid4">
                <div className="profilelabel">Account Status</div>
                <div className={customer?.accountStatus === "Open" ? "green":(customer?.accountStatus === "Closed" ? "red":"")}>
                    {customer?.accountStatus === "None" || customer?.accountStatus === "none" ? "" : customer?.accountStatus}
                </div>
                {customer?.accountStatus === "Closed" &&
                    <>
                    <div className="profilelabel">Closed Reason</div>
                    <div>{AccountStatusMessage(customer?.accountStatusReason)}</div>
                    </>
                }
                {customer?.accountStatus === "Pending" &&
                    <>
                    <div className="profilelabel">Required User Actions</div>
                    { customer?.requiredActions?.map((rqa, idx) => { return (<div key={ `rqa_${idx}` }>{rqa}</div>) }) }
                    </>
                }

                <div className="profilelabel">Realm</div>
                <div>{customer?.realm}</div>
                <div className="profilelabel">Sign Up Date</div>
                {customer?.registrationDate &&
                    <div>{moment(customer?.registrationDate).format("YYYY-MM-DD HH:mm:ss")}</div>
                }
                <div className="profilelabel">Sign Up Status</div>
                <div className={customer?.registrationStatus === "Complete" ? "green":(customer?.registrationStatus === "Closed" ? "red" : "")}>
                    {customer?.registrationStatus === "EmailVerified" ? "Email Verified" :
                    (customer?.registrationStatus === "MobileVerified" ? "Mobile Verified" : customer?.registrationStatus)}</div>
                <div className="profilelabel">ID Verification</div>
                <div>{customer?.verifiedBy}</div>
                <div className="profilelabel">Document</div>
                <CustomerDocumentsList userDocuments={customer?.documents}/>
                <div className="profilelabel">VIP Level</div>
                <div>{customer?.vipLevel === "MobileVerified" ? "Mobile Verified" : customer?.vipLevel}</div>
                <div className="profilelabel">Flag</div>
                <div>{customer?.flagged === true ? "Yes" : "None"}</div>
                <div className="profilelabel">Access to Buy+Send</div>
                <div>{customer?.isDeniedBuyAndSend === true ? "Denied" : "Allowed"}</div>
                <div className="profilelabel">Allowed Withdrawals</div>
                <div>{customer?.isDeniedWithdrawals === true ? "Denied" : "Allowed"}</div>
                <div className="profilelabel">IP Address</div>
                <div>{customer?.ipAddress}</div>
                <div className="profilelabel">Device ID</div>
                {customer?.deviceId?.map((device, idx) => {
                    return(<div className="profilelabeldeviceid" key={ `dev_${idx}` }>{device}</div>);
                })}
                <div className="profilelabel">Device Fingerprint</div>
                {customer?.deviceFingerprints?.map((fingerprint, idx) => {
                    return(<div className="profilelabeldeviceid" key={ `fp_${idx}` }>{fingerprint}</div>);
                })}

            </div>
            <div className="profilegrid5"><button className="buttonprimary primary savecancel" onClick={(e) => {handleEdit()}}>Edit</button></div>
        </div>
    </>
}

export default CustomerProfileViewPage;
